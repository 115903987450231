import React from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import ShareIconGreenOutline from "../../assets/images/shareIconGreenOutline.svg";
import RetryIcon from "../../assets/images/retry.svg";
import { FaExternalLinkAlt } from "react-icons/fa";
import { ContinueModalProps } from "./types";
import { formatUrlWithProtocol, generateCloudinaryImageUrl, getLocalStorage } from "../../utils/common-function";
import mixpanel from "../../services/mixpanelService";
const ContinueModal: React.FC<ContinueModalProps> = (props: ContinueModalProps) => {
  const { isOpen, onClose, campaign, openShareModal, navigateToStashAgain } = props;
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const onboardingFeatureFlag = useFeatureIsOn("onboarding-flow");
  if (!isOpen) return null;

  const playAgainButton = (
    <button
      onClick={() => {
        mixpanel.track("continue-modal-play-again-clicked");
        navigateToStashAgain()
      }}
      className="w-full h-[78px] gap-x-2 p-3 items-center justify-center flex flex-row bg-grey/20 rounded-lg border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8"
    >
      <img src={RetryIcon} alt="Play again" />
      <div className="flex flex-col">
        <div className="text-left font-bold font-primary text-base">Play again</div>
        <div className="text-left text-xs">
          Raise more money when you play a second time.
        </div>
      </div>
    </button>
  )

  const returnToCampaignButton = (
    <button
    onClick={() => {
      mixpanel.track("continue-modal-return-clicked");
      navigateToStashAgain()
    }}
      className="w-full h-[78px] gap-x-2 p-3 items-center justify-center flex flex-row bg-grey/20 rounded-lg border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8"
    >
      <div className="flex flex-col">
        <div className="text-center font-bold font-primary text-base">Back to campaign</div>
      </div>
    </button>
  )

  return (
    <div className="fixed inset-0 bg-modal-bg/80 flex items-center justify-center z-20 backdrop-blur-5" onClick={onClose}>
      <div className="text-base-white rounded-20 w-[90%] relative p-5 pt-6 pb-4 bg-black">
        <div className="space-y-3">
          <div className="font-primary font-bold text-lg w-full text-center">More ways to help</div>
          <button
            className="w-full h-[78px] gap-x-2 p-3 text-sm rounded-lg text-primary-l1 border-primary-l1 border-t-2 border-b-4 border-x-2 border-grey/70 flex align-center justify-center tracking-wide leading-tight-16.8 items-center"
            onClick={() => {
              onClose();
              openShareModal();
              mixpanel.track("continue-modal-share-clicked");
            }}
          >
            <img src={ShareIconGreenOutline} alt="Share" />
            <div className="flex flex-col">
              <div className="text-left font-bold font-primary text-base">Share</div>
              <div className="text-left text-xs">
                Sharing to your social networks raises up to <b>5x more</b>
              </div>
            </div>
          </button>
          {!onboardingFeatureFlag ? (
            <>
              {gamePlayCount <= 1 ? (
                playAgainButton
              ) : null}
            </>
          ) : (
            playAgainButton
          )}
          {campaign?.callToActionUrl?.length ? (
            <button
              onClick={() => {
                mixpanel.track("take-action-clicked", {
                  location: "thank-you-page",
                  campaignName: campaign?.title,
                  charityName: campaign?.charity?.name
                });
                window.open(formatUrlWithProtocol(campaign?.callToActionUrl), "_blank");
              }}
              className="w-full h-[78px] gap-x-3 p-3 items-center justify-center flex flex-row bg-grey/20 rounded-lg border-t-2 border-b-4 border-x-2 border-[#90a8c1]/70 tracking-0.56 text-base-white text-sm leading-tight-16.8">
              <img
                src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "68", "68")}
                alt="Logo"
                className="w-[40px] h-[40px] rounded-full pure-black border-[1px] border-solid border-[#90A8C1]/15"
              />
              <div className="flex flex-col">
                <div className="text-left font-bold font-primary text-base flex">Take action <FaExternalLinkAlt className="ml-1 mt-1 size-3" /></div>
                <div className="text-left text-xs">
                  Discover other ways to support this cause.
                </div>
              </div>
            </button>
          ) : null}
          {gamePlayCount > 1 ? (
            returnToCampaignButton
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ContinueModal;
