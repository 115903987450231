import InputField from "../../../components/common/input/input";
import Button from "../../../components/common/button";
import { buttonTitle, heading, currentPasswordAttributes, changePasswordAttributes } from "../Onboarding/constant";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import useChangePassword from "./hooks/useChangePassword";
import { inputType } from "../../../common/constants";

const ChangePassword = () => {
  const {
    currentPassword,
    newPassword,
    currentPasswordError,
    newPasswordError,
    onChangeCurrentPassword,
    onChangeNewPassword,
    classForText,
    onClickContinueHandler,
    isContinueDisable,
    previousPagePath
  } = useChangePassword();

  return (
    <OnboardingWrapper heading={heading.changePassword} backButtonPath={previousPagePath}>
      <div className="w-full p-4">
        <div className="w-full">
          <InputField
            label={currentPasswordAttributes.label}
            onChange={onChangeCurrentPassword}
            inputClassName={currentPasswordAttributes.className}
            name={currentPasswordAttributes.name}
            type={inputType.password}
            placeholder={currentPasswordAttributes.placeHolder}
            value={currentPassword}
            error={currentPasswordError}
          />
          <InputField
            label={changePasswordAttributes.label}
            onChange={onChangeNewPassword}
            inputClassName={changePasswordAttributes.className}
            name={changePasswordAttributes.name}
            type={inputType.password}
            placeholder={changePasswordAttributes.placeHolder}
            value={newPassword.value}
            error={newPasswordError || classForText(newPassword.value, !isContinueDisable)}
          />
          <div className="text-sm text-grey mb-3 -mt-10">
            <p
              className={classForText(
                newPassword.value,
                !!(newPassword.error.isValidLength && newPassword.error.hasLetterAndNumber && newPassword.error.hasSpecialCharacters)
              )}>
              <strong>Your password must have at least:</strong>
            </p>
            <ul style={{ listStyleType: "disc" }} className="mx-4 leading-16.8px">
              <li className={classForText(newPassword.value, newPassword.error.isValidLength)}>8 characters (20 max)</li>
              <li className={classForText(newPassword.value, newPassword.error.hasLetterAndNumber)}>1 letter and 1 number</li>
              <li className={classForText(newPassword.value, newPassword.error.hasSpecialCharacters)}>
                1 special character (Example: # ? ! $ & @)
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10">
        <Button title={buttonTitle.changePassword} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
      </div>
    </OnboardingWrapper>
  );
};

export default ChangePassword;
