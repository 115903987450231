import { gql } from "@apollo/client";

export const FETCH_STASHLINK = gql`
  query stashlinkById($stashlinkId: ID!) {
    stashlinkById(stashlinkId: $stashlinkId) {
      thankYouMessage
      thankYouMessageVideo
      url
      id
      user {
        id
        fullName
        profilePhoto
        isPublicProfile
        description
        email
        impactScore
        impactLevel
        instagramUsername
        twitterUsername
        youtubeUsername
      }
      campaign {
        id
        endTime
        title
        description
        imageUrl
        videoUrl
        currentAmount
        goalAmount
        location
        cause {
          id
          name
          description
          heroTagline
          tagline
          imageUrl
          hashTags
          videoUrl
          wikipediaUrl
        }
        charity {
          id
          name
          imageUrl
          videoUrl
          description
          numCauses
          totalCoinDrops
          username
          volunteerUrl
          instagramUsername
          twitterUsername
          facebookUsername
          youtubeUsername
          tiktokUsername
        }
        totalCoinDrops
        numSupporters
        canSupport
        shareUrl
        callToActionUrl
        dollarsReceived
        slug
      }
      parentStashlink {
        thankYouMessage
        thankYouMessageVideo
        url
        id
        user {
          id
          fullName
          profilePhoto
          isPublicProfile
          description
          email
          impactScore
          impactLevel
          instagramUsername
          twitterUsername
          youtubeUsername
        }
      }
    }
  }
`;
