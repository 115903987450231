import { useState, useEffect } from "react";
import { confettiDuration } from "../../utils/constants";

const useConfetti = (duration = confettiDuration) => {
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    setIsExploding(true);
    const timer = setTimeout(() => {
      setIsExploding(false);
    }, duration);

    return () => clearTimeout(timer);
  }, []);

  return [isExploding];
};

export default useConfetti;
