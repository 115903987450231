import InputField from "../../../components/common/input/input";
import Button from "../../../components/common/button";
import { buttonTitle, enterYourNameAttributes, heading, validationRules } from "./constant";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import useEnterDob from "./hooks/useEnterDob";
import { routes } from "../../../routes/routes.constant";

const EnterDob = () => {
  const {
    date,
    month,
    year,
    dateRef,
    monthRef,
    yearRef,
    error,
    errorMessage,
    handleDateChangeWithFocus,
    handleMonthChangeWithFocus,
    handleYearChangeWithFocus,
    onClickContinueHandler,
    isContinueDisable,
    addZeroToDateMonth
  } = useEnterDob();

  return (
    <>
      <OnboardingWrapper heading={heading.whenYourBirthday} isTabVisible={true} tabId={1} totalTab={4} backButtonPath={routes.userName}>
        <div className="w-full p-4">
          <div className="w-full">
            <p className="font-primary text-sm text-grey mx-1">Birthday</p>
            <div className="flex space-x-2">
              <InputField
                onChange={handleMonthChangeWithFocus}
                inputClassName="!w-66 !h-11  !py-2 !px-4 text-center"
                name={enterYourNameAttributes.name.firstName}
                placeholder="MM"
                value={month}
                inputMode="numeric"
                inputRef={monthRef}
                handleClick={addZeroToDateMonth}
                handleBlur={addZeroToDateMonth}
              />
              <InputField
                onChange={handleDateChangeWithFocus}
                inputClassName="!w-66 !h-11 !py-2 !px-4 text-center"
                name={enterYourNameAttributes.name.lastName}
                placeholder="DD"
                value={date}
                inputMode="numeric"
                inputRef={dateRef}
                handleClick={addZeroToDateMonth}
                handleBlur={addZeroToDateMonth}
              />
              <InputField
                onChange={handleYearChangeWithFocus}
                inputClassName="!w-76 !h-11 !py-2 !px-4 text-center"
                name={enterYourNameAttributes.name.lastName}
                placeholder="YYYY"
                value={year}
                inputMode="numeric"
                inputRef={yearRef}
                handleClick={addZeroToDateMonth}
              />
            </div>
            {error ? <p className="text-xs font-medium text-error mx-0.5 font-secondary text-left">{error}</p> : <span></span>}
            {errorMessage ? (
              <div className="-mt-10 text-sm">
                <p className="text-xs text-[#fc5555] text-error font-bold">
                  <strong>{errorMessage}</strong>
                </p>
                <ul style={{ listStyleType: "disc" }} className="leading-[16.8px]">
                  <li className="mx-6 text-xs text-[#fc5555] text-error">{validationRules.dob.dateFormat}</li>
                  <li className="mx-6 text-xs text-[#fc5555] text-error">{validationRules.dob.ageLimit}</li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div className="mb-2">
              {!errorMessage ? <p className="font-secondary text-sm text-grey">Your birthday won&apos;t be shown publicly</p> : <></>}
            </div>
          </div>
        </div>
        <div className="fixed w-full bg-soft-black/40 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10">
          <Button title={buttonTitle.continue} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
        </div>
      </OnboardingWrapper>
    </>
  );
};

export default EnterDob;
