import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../../routes/routes.constant";
import { useAuth } from "../../../../store/contextAPI/authContext";
import useUser from "../../../../services/hooks/useUser";
import { getLocalStorage } from "../../../../utils/common-function";
import mixpanel from "../../../../services/mixpanelService";

const useEnterDob = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, setDOB } = useAuth();
  const userId = getLocalStorage("userId");
  const { updateUserProfile } = useUser(userId);
  const [month, setMonth] = useState<string>(user.dobMonth ?? "");
  const [error, setError] = useState<string | null>(null);
  const [date, setDate] = useState<string>(user.dobDay ?? "");
  const [year, setYear] = useState<string>(user.dobYear ?? "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const monthRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const addZeroToDateMonth = () => {
    if (date.length === 1) {
      setDate(`0${date}`);
    } else if (month.length === 1) {
      setMonth(`0${month}`);
    }
  };

  // Modified change handlers to auto-focus next field
  const handleMonthChangeWithFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleMonthChange(e);
    const value = e.target.value;

    if (value.length === 2) {
      setTimeout(() => dateRef.current?.focus(), 10); // Small delay to avoid focus issues
    }
  };

  const handleDateChangeWithFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDateChange(e);
    const value = e.target.value;

    if (value.length === 2) {
      setTimeout(() => yearRef.current?.focus(), 10); // Small delay to avoid focus issues
    }
  };

  const handleYearChangeWithFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleYearChange(e);
  };

  useEffect(() => {
    mixpanel.track("login-onboarding-birthday-viewed");
  }, []);

  const lastValidDOB = useMemo((): string => {
    const currentDate = new Date();
    const dateBefore16Years = new Date(currentDate.getFullYear() - 16, currentDate.getMonth(), currentDate.getDate()).toDateString();
    return dateBefore16Years;
  }, []);

  const handleMonthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let monthValue = event.target.value.replace(/\D/g, ""); // Ensure only digits
    if (monthValue.length > 2) monthValue = monthValue.slice(0, 2); // Trim if needed
    setMonth(monthValue);
  }, []);

  const handleMonthBlur = useCallback(() => {
    if (month.length === 1) {
      setMonth(`0${month}`);
    }
  }, [month]);

  const handleDateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let dayValue = event.target.value.replace(/\D/g, ""); // Ensure only digits
    if (dayValue.length > 2) dayValue = dayValue.slice(0, 2); // Trim if needed
    setDate(dayValue);
  }, []);

  const handleDateBlur = useCallback(() => {
    if (date.length === 1) {
      setDate(`0${date}`);
    }
  }, [date]);

  const handleYearChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let yearValue = event.target.value.replace(/\D/g, ""); // Ensure only digits
    if (yearValue.length > 4) yearValue = yearValue.slice(0, 4); // Trim if needed
    setYear(yearValue);
  }, []);

  const isAgeValid = useCallback((dob: string): boolean => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age: number = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference: number = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference: number = currentDate.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    return age >= 16;
  }, []);

  const validateBirthdate = useCallback((): boolean => {
    const dob = `${year}-${month}-${date}`;
    if (
      month.length < 1 ||
      date.length < 1 ||
      year.length < 4 ||
      Number(parseInt(year, 10)) < 1900 ||
      !isAgeValid(dob) ||
      new Date(dob).toString() === "Invalid Date"
    ) {
      setErrorMessage("Please enter a valid date format");
      return false;
    }
    setErrorMessage("");
    return true;
  }, [date, isAgeValid, month, year, addZeroToDateMonth]);

  const isContinueDisable = useMemo(
    (): boolean => month.length === 0 || year.length === 0 || date.length === 0 || year.length < 4,
    [date.length, month.length, year.length]
  );

  const onUpdateProfileSuccess = useCallback(() => {
    history.push({ pathname: routes.addPhoto, state: location.state });
  }, [history]);

  const onUpdateProfileError = useCallback(() => {
    setError("An error occurred while updating your profile. Please try again.");
  }, []);

  const onClickContinueHandler = useCallback(() => {
    mixpanel.track("login-onboarding-name-birthday-clicked");
    if (validateBirthdate()) {
      setDOB(date, month, year);
      updateUserProfile({ birthdate: `${year}-${month}-${date}` }, onUpdateProfileSuccess, onUpdateProfileError);
    }
  }, [date, history, month, setDOB, validateBirthdate, year, location]);

  return {
    date,
    month,
    year,
    monthRef,
    dateRef,
    yearRef,
    error,
    errorMessage,
    handleMonthBlur,
    handleDateBlur,
    lastValidDOB,
    handleDateChangeWithFocus,
    handleMonthChangeWithFocus,
    handleYearChangeWithFocus,
    onClickContinueHandler,
    isContinueDisable,
    addZeroToDateMonth
  };
};

export default useEnterDob;
