const CoinIcon = ({ color = "#D7FF3E", width = "32", height = "32", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1608_328424)">
      <g clipPath="url(#clip0_1608_328424)">
        <mask id="path-1-outside-1_1608_328424" maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="30" fill="black">
          <rect fill="white" width="29" height="30" />
          <path d="M2 16.5C2 9.59644 7.59644 4 14.5 4C21.4036 4 27 9.59644 27 16.5C27 23.4036 21.4036 29 14.5 29C7.59644 29 2 23.4036 2 16.5Z" />
        </mask>
        <path
          d="M2 16.5C2 9.59644 7.59644 4 14.5 4C21.4036 4 27 9.59644 27 16.5C27 23.4036 21.4036 29 14.5 29C7.59644 29 2 23.4036 2 16.5Z"
          fill={color}
          fillOpacity="0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9679 12.8591L10.3362 17.3815L10.3352 17.3805C10.0986 17.6115 9.71409 17.6115 9.47749 17.3805C9.24089 17.1495 9.24089 16.7741 9.47749 16.5431L23.3478 3H15.9139L5.71827 12.9551C3.42724 15.192 3.42724 18.8182 5.71827 21.0552C8.00929 23.2921 11.7231 23.2921 14.0152 21.0562L18.6691 16.5121C18.9057 16.2811 19.2901 16.2811 19.5267 16.5121C19.7633 16.7431 19.7633 17.1186 19.5267 17.3496L6.57079 30H14.0343L23.2786 20.9737C25.5738 18.7326 25.5738 15.1002 23.2786 12.8591C20.9833 10.618 17.2632 10.618 14.9679 12.8591Z"
          fill={color}
        />
      </g>
      <path
        d="M0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5L25 16.5C25 11.8056 20.299 8 14.5 8C8.70101 8 4 11.8056 4 16.5L0 14.5ZM29 16.5C29 23.9558 22.9558 30 15.5 30H13.5C6.04416 30 0 23.9558 0 16.5H4C4 22.8513 8.70101 28 14.5 28C20.299 28 25 22.8513 25 16.5H29ZM13.5 30C6.04416 30 0 23.9558 0 16.5V14.5C0 6.49187 6.49187 0 14.5 0V8C8.70101 8 4 11.8056 4 16.5C4 22.8513 8.70101 28 14.5 28L13.5 30ZM14.5 0C22.5081 0 29 6.49187 29 14.5V16.5C29 23.9558 22.9558 30 15.5 30L14.5 28C20.299 28 25 22.8513 25 16.5C25 11.8056 20.299 8 14.5 8V0Z"
        fill={color}
        mask="url(#path-1-outside-1_1608_328424)"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1608_328424"
        x="-14"
        y="-14"
        width="57"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1608_328424" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1608_328424" result="shape" />
      </filter>
      <clipPath id="clip0_1608_328424">
        <path
          d="M2 16.5C2 9.59644 7.59644 4 14.5 4C21.4036 4 27 9.59644 27 16.5C27 23.4036 21.4036 29 14.5 29C7.59644 29 2 23.4036 2 16.5Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CoinIcon;
