import React from "react";
import { useHistory } from "react-router-dom";
import SuggestCelebrity from "../../components/suggestCelebrity/suggestCelebrity"
import CelebrityCard from "../../components/celebrityCard/celebrityCard";
import Loader from "../../components/common/loader/loader";
import useFeaturedUsers from "../../services/hooks/useFeaturedUsers";
import { User } from "../../types";

const CelebrityList: React.FC = () => {
  const history = useHistory();
  const { featuredUsersLoading, featuredUsersData } = useFeaturedUsers();

  if (featuredUsersLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );
  return (
    <div className="p-4 bg-soft-black text-white max-w-md min-h-screen mx-auto">
      <div className="bg-soft-black p-4 rounded-lg flex items-center justify-between">
        <div className="relative flex justify-center items-center w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="lightgray"
            className="w-6 h-6 cursor-pointer absolute left-0 z-10"
            onClick={() => history.goBack()}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          <h1 style={{ fontWeight: "bold" }} className="text-white text-lg text-center">
            Fundraise with a celeb
          </h1>
        </div>
      </div>
      <div className="ps-8">
        {featuredUsersData?.map((user: User, index: number) => (
          <CelebrityCard user={user} key={index}/>
        ))}
        <SuggestCelebrity />

      </div>
    </div>
  );
};

export default CelebrityList;
