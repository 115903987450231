import OnboardingWrapper from "../../../containers/onboardingWrapper";
import Loader from "../../../components/common/loader/loader";
import useEditProfile from "./hooks/useEditProfile";
import { heading } from "../Onboarding/constant";
import ProfileInputs from "../../../components/profileInputs/profileInputs";
import ImageCropper from "../../../components/ImageCropper";

const EditProfile = () => {
  const {
    error,
    firstName,
    lastName,
    profileImageUri,
    userBio,
    birthday,
    instagramId,
    tiktokId,
    youtube,
    onChangeFirstName,
    onChangeLastName,
    onChangeBio,
    onBirthdayChange,
    onChangeInstagramId,
    onChangeTiktokId,
    onChangeYoutube,
    onAddPhotoButtonHandler,
    handlePhotoChange,
    fileInputRef,
    cropperRef,
    handleCrop,
    cancelCrop,
    isProfilePictureChange,
    loading,
    isSaveButtonDisable,
    previousPagePath,
    onChangeSetInitialCrop,
    onClickSaveHandler,
    selectedDate,
    handleDateChange
  } = useEditProfile();

  if (loading) {
    return (
      <div className="h-screen w-screen flex justify-center items-center bg-soft-black">
        <Loader />
      </div>
    );
  }

  return isProfilePictureChange ? (
    <ImageCropper
      cropperRef={cropperRef}
      profileImageUri={profileImageUri}
      onChangeSetInitialCrop={onChangeSetInitialCrop}
      cancelCrop={cancelCrop}
      handleCrop={handleCrop}
    />
  ) : (
    <OnboardingWrapper heading={heading.editProfile} backButtonPath={previousPagePath}>
      <ProfileInputs
        {...{
          firstName,
          lastName,
          userBio,
          birthday,
          instagramId,
          tiktokId,
          youtube,
          onChangeFirstName,
          onChangeLastName,
          onChangeBio,
          onBirthdayChange,
          onChangeInstagramId,
          onChangeTiktokId,
          onChangeYoutube,
          onAddPhotoButtonHandler,
          handlePhotoChange,
          fileInputRef,
          profileImageUri,
          error,
          buttonDisabled: isSaveButtonDisable,
          onClickSaveHandler,
          selectedDate,
          handleDateChange
        }}
      />
    </OnboardingWrapper>
  );
};

export default EditProfile;
