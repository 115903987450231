import { useEffect, useRef, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { getLocalStorage } from "../../../utils/common-function";
import { Campaign, SampleEligibleBrandsData, Stashlink, User } from "../../../types";
import { routes } from "../../../routes/routes.constant";
import useAuthStatus from "./useAuthStatus";
import { fifteenSecondDivisions } from "../../../common/constants";
import mixpanel from "../../../services/mixpanelService";
import scroll from "../../../utils/scroll";

const useThankYouLogic = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const parentVideoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const navigation = useHistory();
  const { isUserLoggedIn } = useAuthStatus();
  const userData = getLocalStorage("userData") as User;
  const brandsData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  const campaign = getLocalStorage("campaign") as Campaign;
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const [showParentVideo, setShowParentVideo] = useState(!!stashlink?.parentStashlink);
  const [videoProgress, setVideoProgress] = useState(0);
  const [parentVideoProgress, setParentVideoProgress] = useState(0);
  const [muted, setMuted] = useState(false);
  const location = useLocation();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false);

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state, navigation]);

  useEffect(() => {
    mixpanel.track("video-1-viewed");
    scroll.lock();
    document.addEventListener("visibilitychange", handleVideoPlayback);

    return () => {
      scroll.enable();
      document.removeEventListener("visibilitychange", handleVideoPlayback);
    };
  }, []);

  const handleVideoPlayback = () => {
    const video = showParentVideo ? parentVideoRef.current : videoRef.current;

    if (document.hidden) {
      video?.pause();
    } else {
      video?.play();
    }
  };

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setVideoProgress(progress);
      if (progress === 100 && stashlink?.parentStashlink) {
        setShowParentVideo(false);
        mixpanel.track("video-2-viewed");
        parentVideoRef.current?.play();
      }
    }
  }, [stashlink]);

  const handleParentTimeUpdate = () => {
    if (parentVideoRef.current) {
      const progress = (parentVideoRef.current.currentTime / parentVideoRef.current.duration) * 100;
      setParentVideoProgress(progress);
      if (progress === 100) {
        setShowParentVideo(true);
        setParentVideoProgress(0);
        videoRef.current?.play();
      }
    }
  };

  const handleVideoEnd = () => {
    mixpanel.track("video-1-ended");
    if (!stashlink?.parentStashlink?.thankYouMessageVideo) {
      setIsPlaying(false);
    }
  };

  const handleParentVideoEnd = () => {
    mixpanel.track("video-2-ended");
    setIsPlaying(false);
  };

  useEffect(() => {
    if (!isShareModalOpen) {
      videoRef.current?.addEventListener("timeupdate", handleTimeUpdate);
      videoRef.current?.addEventListener("ended", handleVideoEnd);

      parentVideoRef.current?.addEventListener("timeupdate", handleParentTimeUpdate);
      parentVideoRef.current?.addEventListener("ended", handleParentVideoEnd);

      return () => {
        videoRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
        videoRef.current?.removeEventListener("ended", handleVideoEnd);
        parentVideoRef.current?.removeEventListener("timeupdate", handleParentTimeUpdate);
        parentVideoRef.current?.removeEventListener("ended", handleParentVideoEnd);
      };
    }
  }, [handleTimeUpdate, handleVideoEnd, handleParentTimeUpdate, handleParentVideoEnd]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const startThankYouMessageTimer = () => {
      timer = setInterval(() => {
        setVideoProgress((prev) => {
          if (prev < 100) {
            return prev + fifteenSecondDivisions;
          } else {
            mixpanel.track("message-1-viewed");
            setShowParentVideo(!!stashlink?.parentStashlink);
            mixpanel.track("message-2-viewed");
            clearInterval(timer);
            return 100;
          }
        });
      }, 1000);
    };

    const startParentVideoTimer = () => {
      timer = setInterval(() => {
        setParentVideoProgress((prev) => {
          if (prev < 100) {
            return prev + fifteenSecondDivisions;
          } else {
            clearInterval(timer);
            setShowParentVideo(false);
            setParentVideoProgress(0);
            setVideoProgress(0);
            startThankYouMessageTimer();
            return 100;
          }
        });
      }, 1000);
    };

    if (!stashlink?.thankYouMessageVideo && !showParentVideo) {
      startThankYouMessageTimer();
    }

    if (showParentVideo && !stashlink?.parentStashlink?.thankYouMessageVideo) {
      startParentVideoTimer();
    }

    return () => clearInterval(timer);
  }, [stashlink, showParentVideo]);

  useEffect(() => {
    const webViewTimeout = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }, 500);

    return () => clearTimeout(webViewTimeout);
  }, []);

  useEffect(() => {
    if (isShareModalOpen) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }, [isShareModalOpen]);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        mixpanel.track("video-1-unpaused");
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        mixpanel.track("video-1-paused");
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleParentPlayPause = () => {
    if (parentVideoRef.current) {
      if (parentVideoRef.current.paused) {
        mixpanel.track("video-2-unpaused");
        parentVideoRef.current.play();
        setIsPlaying(true);
      } else {
        mixpanel.track("video-2-paused");
        parentVideoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const changeParentTab = (tab: boolean) => {
    setShowParentVideo(tab);
    setParentVideoProgress(0);
    setVideoProgress(0);
  };

  const navigateToColink = () => {
    isUserLoggedIn
      ? navigation.push({ pathname: routes.callback, state: { from: routes.colink, currentPath: location.pathname, fromValidFlow: true } })
      : navigation.push({
          pathname: routes.onboarding,
          state: {
            currentPath: location.pathname,
            from: routes.colink,
            param: { isNavigateBack: true },
            fromValidFlow: true,
            userName: stashlink.parentStashlink ? stashlink.parentStashlink.user.fullName : stashlink.user.fullName,
            followerProfileImage: stashlink.parentStashlink ? stashlink.parentStashlink.user.profilePhoto : stashlink.user.profilePhoto
          }
        });
  };

  const navigateToStashAgain = () => {
    mixpanel.track("play-again-clicked");
    navigation.replace(`/stashlink/${stashlink.id}`);
  };

  return {
    videoRef,
    parentVideoRef,
    isPlaying,
    stashlink,
    handlePlayPause,
    navigateToColink,
    navigateToStashAgain,
    handleParentPlayPause,
    setShowParentVideo,
    campaign,
    userData,
    brandsData,
    showParentVideo,
    videoProgress,
    parentVideoProgress,
    muted,
    setMuted,
    isShareModalOpen,
    setIsShareModalOpen,
    isContinueModalOpen,
    setIsContinueModalOpen,
    changeParentTab
  };
};

export default useThankYouLogic;
