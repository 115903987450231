import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";
import { buttonTitle, heading } from "../Onboarding/constant";
import useForgotPassword from "./hooks/useForgotPassword";
import { Link } from "react-router-dom";
import Button from "../../../components/common/button";
import CheckEmailBox from "./../../../assets/images/checkYourEmail.svg";
import { withAuthGuard } from "../../../routes/helpers";

const ForgotPasswordConfirmation = () => {
    const { email, onClickContinueHandler } = useForgotPassword();
    return (
      <OnboardingWrapper heading={heading.checkYourEmail} backButtonPath={routes.login}>
        <div className="mb-4">
          <div className="text-sm text-base-white text-center font-secondary">
            <p className="mb-4 mx-6 text-sm text-center text-base-white/80 font-secondary leading-tight-18.2">
              We reached out to you at <span className="font-semibold">{email.value}</span> to help set up a new password if you have an
              account with us.
            </p>
            <p className="mb-4 mx-6 text-sm text-center text-base-white/80 font-secondary leading-tight-18.2">
              Check your spam folder if you don’t see the email, or create a new account{" "}
              <Link to={routes.onboarding} className="font-extrabold">
                here
              </Link>
              .
            </p>
          </div>
          <div className="mt-24 text-grey text-8xl flex justify-center">
            <img src={CheckEmailBox} alt={CheckEmailBox} />
          </div>
        </div>
        <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-6 z-10">
          <Button title={buttonTitle.resend} isDisabled={false} onClick={onClickContinueHandler} />
        </div>
      </OnboardingWrapper>
    );
  };

  export default withAuthGuard(ForgotPasswordConfirmation);