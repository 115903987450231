import React, { useEffect } from "react";
import stashrunlogo from "../../assets/images/Stashrun-beta-logo.svg";
import QRCode from "qrcode.react";
import Loader from "../common/loader/loader";
import useStashLinkHooks from "../../modules/campaign/hooks/useStashLink";
import VerifiedIcon from "./../../assets/images/verified.svg";
import { generateCloudinaryImageUrl, splitName } from "../../utils/common-function";
import { envVariables } from "../../common/constants";

const DesktopView: React.FC = () => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/").filter((segment) => segment);
  let stashlinkId = envVariables.staticStashlinkId;
  if (pathSegments.length === 2 && pathSegments[0] === "stashlink") {
    stashlinkId = pathSegments[1];
  }
  const { getStashLink, stashlink, stashlinkLoading } = useStashLinkHooks();

  useEffect(() => {
    getStashLink({
      variables: {
        stashlinkId: stashlinkId
      }
    });
  }, [getStashLink]);
  const user = stashlink?.user;
  const campaign = stashlink?.campaign;
  const shareUrl = stashlink?.url ? window.location.href : window.location.origin;
  const parentUser = stashlink?.parentStashlink?.user;

  const { firstName, lastName } = splitName(parentUser?.fullName || "");
  const { firstName: colinkUserFirstName, lastName: colinkUserLastName } = splitName(user?.fullName || "");

  if (stashlinkLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );
  return (
    <div className="min-h-screen bg-black bg-cover bg-no-repeat flex-1 content-center pattern-bg">
      <div className="flex justify-between items-center bg-[#1C2126] absolute top-0 left-0 w-full px-4 py-2 select-none">
        <img src={stashrunlogo} alt={stashrunlogo} />
        <button
          onClick={() => window.open("https://www.stashrun.com", "_blank")}
          className="bg-grey-bg text-base-white text-base font-secondary px-4 py-1 rounded-xl">
          What is Stashrun
        </button>
      </div>
      <div className="container max-w-[1220px] px-4 mx-auto grid grid-cols-12 gap-4 items-center">
        <div className="llg:col-span-5 xl:col-span-5 bg-soft-black text-center text-white rounded-4xl border-r-8 border-b-8 border-primary-l1 overflow-hidden transform lg:scale-75 xl:scale-100" style={{width: "70%"}}>
          <div className="relative">
            {parentUser ? (
              <>
                <div className="relative flex gap-1 w-full xl:h-236 llg:h-136">
                  <img
                    src={generateCloudinaryImageUrl(parentUser.profilePhoto || "", "846", "470")}
                    alt={parentUser.fullName}
                    className="relative w-2/4 h-full  object-cover"
                  />
                  <img
                    src={generateCloudinaryImageUrl(user?.profilePhoto || "", "846", "470")}
                    alt={user?.fullName}
                    className="relative w-2/4 h-full max-w-full  object-cover"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="relative flex gap-1 w-full xl:h-236 llg:h-185">
                  <img
                    src={generateCloudinaryImageUrl(user?.profilePhoto || "", "846", "470")}
                    alt={user?.fullName}
                    className="relative w-full h-full w-full object-cover"
                  />
                </div>
              </>
            )}
            <div className="absolute w-16 h-16 bg-soft-black bottom-0 right-0 rounded-b-none rounded-full p-1">
              <img
                src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "135", "134")}
                alt="Logo"
                className=" w-full h-full rounded-full"
              />
            </div>
          </div>

          {parentUser ? (
            <>
              <div className="w-full text-primary-l1 xxl:text-[20px] llg:text-[16px] xxl:mt-5 llg:mt-1.5 px-2 text-center font-primary font-extrabold flex justify-center items-center inline-block">
                {firstName}{" "}
                <span className="inline-block">
                  {lastName}
                  {parentUser?.isPublicProfile && (
                    <img src={VerifiedIcon} alt={VerifiedIcon} className="ml-1.5 w-3 h-3 inline-block align-middle relative -top-1.5" />
                  )}
                </span>
              </div>
              <div className="w-full text-primary-l1 xxl:text-[20px] llg:text-[16px] xxl:mt-0 llg:mt-0 px-2 text-center font-primary font-extrabold flex justify-center items-center inline-block">
                X
              </div>
              <div className="w-full text-primary-l1 xxl:text-[20px] llg:text-[16px] xxl:mt-0 llg:mt-0 px-2 text-center font-primary font-extrabold flex justify-center items-center inline-block">
                {colinkUserFirstName}{" "}
                <span className="inline-block">
                  {colinkUserLastName}
                  {user?.isPublicProfile && (
                    <img src={VerifiedIcon} alt={VerifiedIcon} className="ml-1.5 w-3 h-3 inline-block align-middle relative -top-1.5" />
                  )}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="w-full text-primary-l1 xxl:text-[28px] llg:text-[16px] xxl:mt-5 llg:mt-2 px-1 text-center font-primary font-extrabold flex justify-center items-center inline-block">
                {colinkUserFirstName}{" "}
                <span className="inline-block">
                  {colinkUserLastName}
                  {user?.isPublicProfile && (
                    <img src={VerifiedIcon} alt={VerifiedIcon} className="ml-1.5 w-3 h-3 inline-block align-middle relative -top-1.5" />
                  )}
                </span>
              </div>
            </>
          )}

          <div className="xxl:p-4 llg:py-2">
            <p className="xxl:text-lg llg:text-xs text-white mb-1">is stashing for</p>
            <h2 className="xxl:text-2xl llg:text-xs font-bold font-primary text-base-white xl:mb-6 llg:mb-2">{campaign?.charity?.name}</h2>
            <div className="mx-auto mt-4 w-fit">
              <QRCode value={shareUrl} size={128} bgColor="transparent" fgColor="#ffffff" />
            </div>
            <p className="xxl:text-lg llg:text-xs text-white xl:mt-4 llg:mt-2 font-secondary xl:px-8 llg:px-0">
              Use the camera on your phone to scan this QR code
            </p>
          </div>
        </div>
        <div className="llg:col-span-7 xl:col-span-7 text-base-white pl-12 pl-32">
          <img src={stashrunlogo} alt="Stashrun Logo" className="h-16" />
          <h3 className="text-40px  mb-10 mt-2 font-semibold leading-tight-48 font-primary">Next-Gen Giving</h3>
          <p className="text-32px font-secondary">
            Scan this QR code and <span className="font-bold">play</span> to fund.
          </p>
          <p className="text-2xl font-secondary text-grey mt-4 italic">Stashrun is only available on mobile for now.</p>
        </div>
      </div>
    </div>
  );
};

export default DesktopView;
