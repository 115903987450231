import React, { useEffect, useState } from "react";
import bubbleGame from "../../assets/images/HomePageGameImage.svg";
import { defaultCoLinkUserLevel, marqueeTextIteration } from "../../utils/constants";
import BulbIcon from "./../../assets/images/bulb.svg";
import { useHistory, useLocation } from "react-router-dom";
import useStashLinkHooks from "../campaign/hooks/useStashLink";
import Loader from "../../components/common/loader/loader";
import MarqueeComponent from "../../components/common/marquee/marqueeComponent";
import StashrunLogo from "./../../assets/images/Stashrun-beta-logo.svg";
import mixpanel from "../../services/mixpanelService";
import { generateCloudinaryImageUrl, getImpactLevel, getLocalStorage, setLocalStorage } from "../../utils/common-function";
import Button from "../../components/common/button";
import Signature from "../../assets/images/signature.svg";
import FollowingCheck from "../../assets/images/following-check.svg";
import useCampaignFunded from "../campaign/hooks/useCampaignFunded ";
import WaitlistModal from "../../components/waitlistModal";
import { envVariables } from "../../common/constants";
import useColinkExist from "../../services/hooks/useColinkExist";
import { routes } from "../../routes/routes.constant";
import useAuthStatus from "../campaign/hooks/useAuthStatus";
import { Stashlink } from "../../types";
import CampaignCard from "../../components/campaignCard/campaignCard";
import useUserStashlinks from "../../services/hooks/useUserStashlinks";
import useWaitlist from "../campaign/hooks/useWaitlist";
import ShareComponent from "../../components/shareModal/shareModal";
import { IoMdHeart } from "react-icons/io";
import { ProtectedRoute } from "../../routes/helpers";
import useCampaign from "../campaign/hooks/useCampaign";

const NewHomePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { userName?: string; followerProfileImage?: string; param?: any };
  const { getStashLink, stashlink, stashlinkLoading } = useStashLinkHooks();
  const user = stashlink?.user;
  const campaign = stashlink?.campaign;
  const isCampaignFunded = useCampaignFunded(campaign);
  const isUserJoinedWaitlist = getLocalStorage("isUserJoinedWaitlist");
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const { colinkExistLoading, colinkExistData } = useColinkExist(stashlink?.id || "");
  const { isUserLoggedIn, currentUser } = useAuthStatus();
  const { activeUserStashlinks } = useUserStashlinks(currentUser?.user.id || "");
  const { handleJoinWaitlist, isWaitlistModalOpen, setWaitlistModalOpen } = useWaitlist();
  const { fetchCampaignAttempts, campaignAttemptsData } = useCampaign();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [shareModalStashlink, setShareModalStashlink] = useState<Stashlink | null>(null);
  const loggedInUserId = getLocalStorage("userId");

  useEffect(() => {
    getStashLink({
      variables: {
        stashlinkId: state?.param?.stashLinkId || envVariables.staticStashlinkId
      }
    });
  }, [getStashLink]);

  useEffect(() => {
    if (campaign?.id) {
      fetchCampaignAttempts({
        variables: {
          campaignId: campaign?.id
        }
      });
    }
  }, [campaign?.id, loggedInUserId, stashlink]);

  useEffect(() => {
    if (campaignAttemptsData && loggedInUserId) {
      const actualPlayCount =
        campaignAttemptsData.numAttemptsToSupportCampaign > gamePlayCount
          ? campaignAttemptsData.numAttemptsToSupportCampaign
          : gamePlayCount;
      setLocalStorage("gamePlayCount", actualPlayCount);
    }
  }, [campaignAttemptsData, loggedInUserId]);

  const closeWaitListModal = () => {
    setWaitlistModalOpen(false);
  };

  if (stashlinkLoading || colinkExistLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center bg-soft-black">
        <Loader />
      </div>
    );

  const onJoinWaitList = async () => {
    await handleJoinWaitlist();
  };

  const stashUser = {
    profilePhoto: shareModalStashlink?.user?.profilePhoto,
    fullName: shareModalStashlink?.user?.fullName,
    isPublicProfile: shareModalStashlink?.user?.isPublicProfile
  };

  const shareUrl = `${envVariables.domainUrl}/stashlink/${shareModalStashlink?.id}`;

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const stashLinkUserImpactScoreLevel = shareModalStashlink?.parentStashlink?.user
    ? defaultCoLinkUserLevel
    : shareModalStashlink?.user?.impactScore
      ? getImpactLevel(shareModalStashlink?.user?.impactScore)
      : "1";

  return (
    <div className="bg-softblack text-white">
      <div className="bg-black">
        <MarqueeComponent
          text="no cost to play&nbsp;&nbsp;&nbsp;❤&nbsp;&nbsp;&nbsp;no cost to donate&nbsp;&nbsp;&nbsp;❤"
          iterationsPerScreen={marqueeTextIteration}
          // separatorIcon={FaHeart}
        />
      </div>
      <div className="p-4">
        {!isCampaignFunded && gamePlayCount < 2 ? (
          <>
            <div className="relative">
              <h1 className="text-lg text-neutral-50 font-primary font-bold mb-2">Stash with a celeb</h1>
            </div>
            <div className="relative overflow-hidden" onClick={() => history.push(`/stashlink/${stashlink?.id}`)}>
              <img
                src={generateCloudinaryImageUrl(user?.profilePhoto || "", "846", "470")}
                alt={user?.fullName}
                className="w-full h-c-245 rounded-2xl rounded-tr-3xl object-cover"
              />
              <div className="absolute p-2 bg-soft-black -top-2 -right-2 rounded-full flex justify-center items-center">
                <img
                  src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "135", "135")}
                  alt="Logo"
                  className="w-c-82 h-c-82 rounded-full"
                />
              </div>
              <div className="absolute bottom-4 left-4">
                <h2 className="text-28px font-extrabold leading-tight-29.54 capitalize text-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.32)]">
                  {user?.fullName}
                </h2>
              </div>
            </div>
          </>
        ) : (
          <div className="bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg rounded-t-28 rounded-b-20 p-4 mx-auto border border-b-8 border-black">
            <div className="relative overflow-hidden">
              <img
                src={generateCloudinaryImageUrl(user?.profilePhoto || "", "622", "365")}
                alt={user?.fullName}
                className="rounded-xl rounded-bl-38 w-full h-182 object-cover"
              />
              <img
                src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "148", "148")}
                alt="Logo"
                className="absolute -bottom-2 -left-2 rounded-full w-c-74 h-c-74 border-8 border-[#30390E] object-cover"
              />
            </div>
            <div className="mt-4 text-left">
              <p className="mb-4 font-secondary text-sm text-base-white font-medium leading-tight-16.8">
                Thanks for being a part of {user?.fullName.split(" ")[0]}&apos;s community and be the first to know when the next play to
                fund campaign goes live.
              </p>
              <p className="mb-4 font-secondary text-sm text-base-white font-medium leading-tight-16.8">
                Stashrun lets you play games to fund charities, together with your friends and favorite celebs.
              </p>
              <a className="text-[#419CFF] underline font-secondary text-base">Sign up to vote</a>
            </div>
            <div className="relative mt-4 flex justify-between items-center">
              <p className="font-secondary text-base text-left text-base-white">XOXO, {user?.fullName}</p>
              <img src={Signature} alt="Signature" className="absolute right-4 -bottom-1 w-c-70 h-auto" />
            </div>
            <div className="w-full gap-[8.01px] mt-4">
              {!isUserJoinedWaitlist ? (
                <Button onClick={onJoinWaitList} isDisabled={false} title="Join the waitlist" />
              ) : (
                <div className="w-full mt-2 px-0 h-c-48">
                  <button className="w-full h-12 px-4 py-2 text-lg font-primary tracking-wider font-bold rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                    On waitlist{" "}
                    <span className="ml-1">
                      <img src={FollowingCheck} alt="Following Check"></img>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="mt-6">
          {gamePlayCount === 2 &&
            (colinkExistData && activeUserStashlinks.length > 0 ? (
              // <div className="relative pt-4">
              //   <h1 className="text-neutral-50 font-primary font-wght-750 text-lg mb-2">Share your stashlinks</h1>
              //   <div className="flex flex-col space-y-8">
              //     {activeUserStashlinks?.map((stashlink: Stashlink, index: number) => (
              //       <CampaignCard
              //         stashlink={stashlink}
              //         setIsShareModalOpen={setIsShareModalOpen}
              //         setShareModalStashlink={setShareModalStashlink}
              //         key={index}
              //       />
              //     ))}
              //   </div>
              // </div>
              <></>
            ) : (
              <>
                <div className="relative">
                  <h1 className="text-lg text-neutral-50 font-primary font-bold mb-2">Stash with a celeb</h1>
                </div>
                <div
                  className="relative overflow-hidden"
                  onClick={() =>
                    isUserLoggedIn
                      ? history.push({
                          pathname: routes.callback,
                          state: { currentPath: location.pathname, from: routes.colink, fromValidFlow: true }
                        })
                      : history.push({
                          pathname: routes.onboarding,
                          state: {
                            currentPath: location.pathname,
                            from: routes.colink,
                            userName: user?.fullName,
                            fromValidFlow: true,
                            followerProfileImage: user?.profilePhoto
                          }
                        })
                  }>
                  <img
                    src={generateCloudinaryImageUrl(user?.profilePhoto || "", "846", "470")}
                    alt={user?.fullName}
                    className="w-full h-c-245 rounded-2xl rounded-tr-3xl object-cover"
                  />
                  <div className="absolute p-2 bg-soft-black -top-2 -right-2 rounded-full flex justify-center items-center">
                    <img
                      src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "135", "135")}
                      alt="Logo"
                      className="w-c-82 h-c-82 rounded-full"
                    />
                  </div>
                  <div className="absolute bottom-4 left-4">
                    <h2 className="text-28px font-extrabold leading-tight-29.54 capitalize text-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.32)]">
                      {user?.fullName}
                    </h2>
                  </div>
                </div>
              </>
            ))}

          {isUserLoggedIn && activeUserStashlinks.length > 0 && (
            <div className="relative pt-4">
              <h1 className="text-neutral-50 font-primary font-wght-750 text-lg mb-4 leading-tight-21.6">Share your stashlinks</h1>
              <div className="flex flex-col space-y-8">
                {activeUserStashlinks?.map((stashlink: Stashlink, index: number) => (
                  <CampaignCard
                    stashlink={stashlink}
                    setIsShareModalOpen={setIsShareModalOpen}
                    setShareModalStashlink={setShareModalStashlink}
                    key={index}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {isCampaignFunded && (
          <div className="bg-grey/5 p-4 border-2 border-black rounded-3xl mt-12 text-center flex flex-col justify-center items-center">
            <img src={BulbIcon} alt="Bulb Icon" className="w-10" />
            <h3 className="font-primary text-base text-white mb-3 mt-4">Suggest something</h3>
            <p className="font-secondary text-sm text-gray-400 mb-3">
              Tell us what celebs, influencers, brands or charities you want to see on Stashrun!
            </p>
            <a
              onClick={() => mixpanel.track("suggest-clicked", { location: "home" })}
              href="https://www.stashrun.com/suggest"
              target="_blank"
              rel="noreferrer"
              className="font-secondary text-primary-l1 text-sm">
              Make a suggestion
            </a>
          </div>
        )}

        <div className="mt-16 rounded-lg flex flex-col px-2">
          <div>
            <img src={StashrunLogo} alt={StashrunLogo} className="h-[18px] w-auto" />
            <p className="opacity-80 text-neutral-50 text-sm font-normal font-secondary leading-[18.20px] mt-3">
              Play to fund charities together with <br />
              friends and your favorite celebs.
            </p>
            <p className="mt-4 opacity-80 text-[#b0c1d3] text-xs font-normal font-secondary leading-none inter-I">© 2024 Stashrun Inc</p>
            <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-secondary leading-none ">
              Made with <IoMdHeart className="text-sm inline relative -top-0.5" /> from California
            </p>
            <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-secondary leading-none underline-offset-4">
              <a
                onClick={() =>
                  mixpanel.track("terms-clicked", {
                    location: "homepage"
                  })
                }
                target="_blank"
                href="https://www.stashrun.com/terms"
                className="underline"
                rel="noreferrer">
                Terms
              </a>
              {"  "}•{"  "}
              <a
                onClick={() =>
                  mixpanel.track("privacy-clicked", {
                    location: "homepage"
                  })
                }
                target="_blank"
                href="https://www.stashrun.com/privacy"
                className="underline"
                rel="noreferrer">
                Privacy
              </a>
            </p>
          </div>

          <div className="w-full -mt-40">
            <img src={bubbleGame} alt="game bubble" className="w-full max-w-md h-auto mx-auto mb-6" />
          </div>
        </div>
      </div>
      <WaitlistModal
        stashUser={stashlink?.parentStashlink?.user || stashlink?.user}
        isOpen={isWaitlistModalOpen}
        onClose={closeWaitListModal}
      />
      {isShareModalOpen && (
        <ShareComponent
          stashUser={shareModalStashlink?.parentStashlink?.user || stashUser}
          charityName={shareModalStashlink?.campaign?.charity?.name}
          charityLogo={shareModalStashlink?.campaign?.charity?.imageUrl}
          currentAmount={shareModalStashlink?.campaign?.currentAmount}
          goalAmount={shareModalStashlink?.campaign?.goalAmount}
          shareUrl={shareUrl}
          coLinkUser={shareModalStashlink?.parentStashlink?.user ? shareModalStashlink?.user : undefined}
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
          impactScoreLevel={stashLinkUserImpactScoreLevel}
        />
      )}
    </div>
  );
};

export default ProtectedRoute(NewHomePage);
