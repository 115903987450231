import React from "react";
import Button from "../../../components/common/button";
import Coin from "../../../assets/images/StashCoin.png";
import { InstructionalModalProps } from "../types";
import InstructionModalTapIcon from "./../../../assets/images/instruction-modal-hand-80-52.png";

const InstructionalModal: React.FC<InstructionalModalProps> = ({ isOpen, onClose, brandName }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 backdrop-blur">
      <div className="bg-grey-bg text-base-white rounded-3xl w-80 relative border-r-4 border-b-4 border-primary-l1">
        <div className="bg-black/0 px-4 py-6 pt-8 rounded-2xl">
          <h2 className="text-22px text-base-white font-bold text-center mb-4">How to Stash</h2>
          <div className="relative bg-black/30 px-4 py-1 pt-3 rounded-2xl">
            <p className="text-center text-base text-neutral-50 font-secondary leading-tight mb-4"><span className="font-bold">Tap</span> the coins as <br /> <span className="font-bold">fast</span> as you can.</p>
            <div className="flex justify-between items-start mb-4">
              <div className="w-10 h-10 bg-grey-op70/30 rounded-full flex items-center justify-center"></div>

              <div className="w-10 h-10 bg-grey-op70/300 rounded-full flex items-center justify-center shadow-[0_5px_0px_rgba(0,0,0,1)]">
                <img className="w-10 h-10 flex items-center justify-center object-cover object-top rounded-full" src={Coin} />
              </div>
              <div className="w-10 h-10 bg-grey-op70/30 rounded-full"></div>
              <div className="w-10 h-10 bg-grey-op70/30 rounded-full"></div>
              <div className="w-10 h-10 bg-grey-op70/30 rounded-full flex items-center justify-center shadow-[0_5px_0px_rgba(0,0,0,1)]">
                <img className="w-10 h-10 flex items-center justify-center object-cover object-top rounded-full" src={Coin} />
              </div>
            </div>
            <img src={InstructionModalTapIcon} alt={InstructionModalTapIcon} className="absolute -bottom-4 -right-10 w-20 -rotate-[130deg]" />
          </div>
          <p className="text-center text-base text-neutral-50 leading-tight mb-7 mt-6">
            Every coin you tap is <span className="text-primary-l1 font-bold">real money</span> donated by{" "}
            <span className="font-bold">{brandName}</span>
          </p>
          <Button title="Got it!" isDisabled={false} onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default InstructionalModal;
