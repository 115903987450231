import React from "react";
import InputField from "../../../components/common/input/input";
import Button from "../../../components/common/button";
import { buttonTitle, heading, setPasswordAttributes } from "../Onboarding/constant";
import useEnterPassword from "./hooks/useEnterPassword";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";
import { inputType } from "../../../common/constants";
import { Link } from "react-router-dom";
import { withAuthGuard } from "../../../routes/helpers";

const EnterPassword = () => {
  const { password, onChangePassword, onClickContinueHandler, isContinueDisable } = useEnterPassword();
  return (
    <OnboardingWrapper heading={heading.enterPassword} backButtonPath={routes.onboarding}>
      <div className="w-full p-4 pt-3">
        <div className="w-full">
          <InputField
            label={setPasswordAttributes.label}
            onChange={onChangePassword}
            inputClassName={setPasswordAttributes.className}
            name={setPasswordAttributes.name}
            type={inputType.password}
            placeholder={setPasswordAttributes.placeHolder}
            value={password.value}
            error={password.error}
          />
          <div className="flex justify-between items-center -mt-10">
            {password.error ? (
              <p className="text-xs font-medium text-error mx-0.5 font-secondary text-left">{password.error}</p>
            ) : (
              <span></span>
            )}
            <div className="text-xs text-grey font-bold underline font-secondary">
              <Link to={routes.forgotPassword}>Forgot Password</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10">
        <Button title={buttonTitle.continue} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
      </div>
    </OnboardingWrapper>
  );
};

export default withAuthGuard(EnterPassword);
