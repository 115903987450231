const LockIcon = ({ color = "#EA9A00", width = "13", height = "12", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.50195 5.00098V3.50098V3.50098C4.50195 2.39648 5.39746 1.50098 6.50196 1.50098V1.50098C7.60647 1.50098 8.50198 2.39648 8.50198 3.50098V3.50098V5.00098"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00394 10.502H4.00391C3.45141 10.502 3.00391 10.0545 3.00391 9.50195V6.00195C3.00391 5.44945 3.45141 5.00195 4.00391 5.00195H9.00394C9.55644 5.00195 10.0039 5.44945 10.0039 6.00195V9.50195C10.0039 10.0545 9.55644 10.502 9.00394 10.502ZM6.50632 6.50226C6.78246 6.50226 7.00632 6.72611 7.00632 7.00226V8.50226C7.00632 8.7784 6.78246 9.00226 6.50632 9.00226C6.23017 9.00226 6.00632 8.7784 6.00632 8.50226V7.00226C6.00632 6.72611 6.23017 6.50226 6.50632 6.50226Z"
      fill={color}
    />
  </svg>
);

export default LockIcon;
