import React from "react";
import { ColinkUsersCardProps } from "./colinkUsersCard.types";
import ShareIcon from "./../../assets/images/shareIconArrow.svg";
import { generateCloudinaryImageUrl, getStyles, splitName } from "../../utils/common-function";
import VerifiedIcon from "../svgImages/verifiedIcon";

const ColinkUsersCard: React.FC<ColinkUsersCardProps> = (props: ColinkUsersCardProps) => {
  const { parentUser, colinkUser, isShareIconRequired } = props;
  const openShareModal = () => {
    props.setIsShareModalOpen && props.setIsShareModalOpen(true);
  };
  const { firstName, lastName } = splitName(parentUser.fullName);
  const { firstName: colinkUserFirstName, lastName: colinkUserLastName } = splitName(colinkUser?.fullName || "");
  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="relative w-full flex flex-col items-center text-center">
      <div className="relative w-full h-229  flex justify-center items-center grid grid-cols-2">
        {!isShareIconRequired && (
          <div className="absolute w-full top-4 flex justify-center z-10">
            <div className="font-inter font-extrabold inter-I text-white text-13.77px backdrop-blur-5 tracking-0.964px w-105.93 h-33.105 py-8.21 px-17.87 flex items-center previewRotate justify-center leading-none rounded-8.21 border-1.39 border-white bg-black/30 shadow-previewShadow text-shadow-custom uppercase">
              Preview
            </div>
          </div>
        )}
        <div className="relative h-229  border-r-2 border-black">
          <img
            src={generateCloudinaryImageUrl(parentUser?.profilePhoto || "", "370", "458")}
            alt={parentUser?.fullName}
            className="h-229 w-full object-cover"
          />
        </div>
        <div className="relative h-229 border-l-2 border-black">
          <img
            src={generateCloudinaryImageUrl(colinkUser?.profilePhoto || "", "370", "458")}
            alt={colinkUser?.fullName}
            className="h-229 w-full object-cover"
          />
        </div>
      </div>
      {isShareIconRequired && (
        <div className="p-0.5 bg-black/40 rounded-full flex justify-center items-center absolute top-3 right-3 backdrop-filter-blur w-7 h-7">
          <img src={ShareIcon} alt={ShareIcon} onClick={() => openShareModal()} />
        </div>
      )}

      <div
        className="w-full bg-gradient-to-t from-black from-50% via-black/70 via-80% to-black/0 to-100% -mt-12 z-10"
        style={{ color: styles.levelColor }}>
        <p className="break-words w-full text-xl  text-center font-primary font-extrabold flex justify-center items-center inline-block">
          {firstName}{" "}
          <span className="inline-block">
            {lastName}
            {parentUser?.isPublicProfile && (
              <VerifiedIcon color={styles.levelColor} className="w-3 h-3 inline-block ml-1.5 align-middle relative -top-1.5" />
            )}
          </span>
        </p>
        <p className="text-xl text-center font-primary font-extrabold -mt-2 -mb-2">
          <strong>x</strong>
        </p>
        <p className="break-words w-full text-xl  text-center font-primary font-extrabold flex justify-center items-center inline-block">
          {colinkUserFirstName}{" "}
          <span className="inline-block">
            {colinkUserLastName}
            {colinkUser?.isPublicProfile && (
              <VerifiedIcon color={styles.levelColor} className="w-3 h-3 inline-block ml-1.5 align-middle relative -top-1.5" />
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ColinkUsersCard;
