import React from "react";
import classNames from "classnames";
import { CircularLoaderProps } from "./loader.types";

const Loader: React.FC<CircularLoaderProps> = ({ size = 52, strokeWidth = 2, className }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;

  return (
    <svg className={classNames("animate-spin", className)} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        className="text-transparent"
        strokeWidth={strokeWidth}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="text-primary-l1"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={circumference * 0.25}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
    </svg>
  );
};

export default Loader;
