import { inputType } from "../../../common/constants";
import InputField from "../../../components/common/input/input";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";
import { buttonTitle, emailAttributes, heading, subHeading } from "../Onboarding/constant";
import useForgotPassword from "./hooks/useForgotPassword";
import Button from "../../../components/common/button";
import { withAuthGuard } from "../../../routes/helpers";

const ForgotPassword = () => {
    const { email, onChangeEmail, isContinueDisable, onClickContinueHandler } = useForgotPassword();
    return (
      <OnboardingWrapper heading={heading.forgotPassword} backButtonPath={routes.login}>
        <div className="w-full h-10 hello">
          <p className="w-full text-sm text-gray-400 text-center font-secondary">{subHeading.forgotPassword}</p>
        </div>
        <div className="w-full p-4">
          <div className="w-full">
            <InputField
              label={emailAttributes.label}
              onChange={onChangeEmail}
              inputClassName={emailAttributes.className}
              name={emailAttributes.name}
              type={inputType.text}
              placeholder={emailAttributes.placeHolder}
              value={email.value}
              error={email.error}
              showClearIcon={email.value.length !== 0}
            />
            {email.error && <p className="text-xs text-error mx-2 mb-2">{email.error}</p>}
          </div>
        </div>
        <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-6 z-10">
          <Button title={buttonTitle.continue} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
        </div>
      </OnboardingWrapper>
    );
  };

  
export default withAuthGuard(ForgotPassword);