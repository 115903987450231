import React, { useEffect, useState } from "react";
import {
  convertToPercentage,
  formatUrlWithProtocol,
  generateCloudinaryImageUrl,
  getLocalStorage,
  setLocalStorage
} from "../../utils/common-function";
import { PlayToFundProps } from "./playToFund.types";
import { useHistory } from "react-router";
import { routes } from "../../routes/routes.constant";
import { buttonTitle } from "../../modules/Accounts/Onboarding/constant";
import { formatCurrencyToUsd } from "../../utils/format";
import FollowingCheck from "./../../assets/images/follow-check.svg";
// import Button from "../common/button";
import useColinkExist from "../../services/hooks/useColinkExist";
import { Stashlink } from "../../types";
import ProgressBar from "../common/progressBar";
import mixpanel from "../../services/mixpanelService";
import clsx from "clsx";
// import useWaitlist from "../../modules/campaign/hooks/useWaitlist";
import MarqueeComponent from "../common/marquee/marqueeComponent";
import { marqueeTextIteration } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import { CREATE_WAITLIST } from "../../queries";
import useAuthStatus from "../../modules/campaign/hooks/useAuthStatus";

const PlayToFund: React.FC<PlayToFundProps> = (props: PlayToFundProps) => {
  const {
    campaign,
    setIsShareModalOpen,
    isCampaignFunded,
    // isUserFollowing,
    isCurrentUserStashLink,
    isUserLoggedIn,
    // followCelebrity,
    celebrityName,
    onToggle,
    userProfile,
    onboardingFeatureFlag,
    setWaitlistModalOpen
  } = props;
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const percentageCompleted = convertToPercentage(campaign?.currentAmount, campaign?.goalAmount);
  const navigation = useHistory();
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const { colinkExistData } = useColinkExist(stashlink?.id || "");
  const [isUserJoinedWaitlist, setIsUserJoinedWaitlist] = useState<boolean>(getLocalStorage("isUserJoinedWaitlist") === true);
  const [createWaitlist] = useMutation(CREATE_WAITLIST);
  const { currentUser } = useAuthStatus();

  useEffect(() => {
    if (!isUserJoinedWaitlist) {
      const intervalId = setInterval(() => {
        const waitlistStatus = getLocalStorage("isUserJoinedWaitlist") === true;
        if (isUserJoinedWaitlist !== waitlistStatus) {
          setIsUserJoinedWaitlist(waitlistStatus);
        }
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [isUserJoinedWaitlist]);

  const openShareModal = () => {
    setIsShareModalOpen && setIsShareModalOpen(true);
  };

  const onJoinWaitList = async () => {
    mixpanel.track("join-waitlist-clicked", {
      campaignName: campaign?.title,
      charityName: campaign?.charity?.name
    });
    currentUser?.user.email
      ? await createWaitlist({
          variables: {
            email: currentUser?.user.email,
            accessAllowed: true
          }
        }).then(() => setLocalStorage("isUserJoinedWaitlist", true))
      : setWaitlistModalOpen && setWaitlistModalOpen(true);
  };

  return (
    <div className="fixed bottom-0 w-full bg-black/70 backdrop-blur-xl pb-5 pt-0">
      <div className="px-4">
        <div className="flex items-center justify-between w-full py-1.5" style={{ userSelect: "none" }}>
          {!isCurrentUserStashLink && (
            <MarqueeComponent
              text="no cost to play&nbsp;&nbsp;&nbsp;❤&nbsp;&nbsp;&nbsp;no cost to donate&nbsp;&nbsp;&nbsp;❤"
              iterationsPerScreen={marqueeTextIteration}
              // separatorIcon={FaHeart}
            />
          )}
          {isCurrentUserStashLink && isCampaignFunded && (
            <div className="py-1.5">
              <MarqueeComponent text="GOAL REACHED" iterationsPerScreen={marqueeTextIteration} separatorIcon={"🎉"} />
            </div>
          )}
        </div>

        <div>
          <ProgressBar percentageCompleted={percentageCompleted} />
        </div>

        {isCampaignFunded ? (
          <div className="text-center mt-1">
            <div className="mb-1">
              <span className="font-primary font-semibold text-xl">{formatCurrencyToUsd(campaign?.goalAmount)}</span>
              <span role="img" aria-label="party" className="mx-3">
                🎉
              </span>
              <span className="text-primary-l1 text-base font-bold font-secondary">GOAL REACHED!</span>
            </div>
            {isUserLoggedIn && onboardingFeatureFlag ? (
              isCurrentUserStashLink ? (
                <></>
              ) : (
                //   <>
                //   {isUserFollowing ? (
                //     <div className="w-full gap-2.5 pb-1.5 rounded-lg mt-3">
                //       <button
                //         className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-740 rounded-lg text-grey bg-grey/15 border-2 border-grey/70 flex align-center justify-center"
                //         onClick={() => {
                //           mixpanel.track("following-button-clicked");
                //           navigation.push("/");
                //         }}>
                //         Following
                //         <span className="ml-1">
                //           <img src={FollowingCheck} alt="Following Check"></img>
                //         </span>
                //       </button>
                //     </div>
                //   ) : (
                //     <Button
                //       onClick={() => {
                //         mixpanel.track("follow-button-clicked");
                //         followCelebrity();
                //       }}
                //       isDisabled={false}
                //       title={`Follow ${celebrityName?.split(" ")[0]}`}
                //     />
                //   )}
                // </>
                <>
                  {isUserJoinedWaitlist ? (
                    <div className="w-full">
                      <button className="w-full h-12 px-4 py-3 items-center text-base font-primary tracking-wider leading-tight-19.2 font-bold rounded-lg text-sm text-grey bg-grey/20 border border-grey/70 flex align-center justify-center">
                        On waitlist
                        <span className="ml-1.5 w-6 h-6 relative -top-0.5">
                          <img src={FollowingCheck} alt="On waitlist Check"></img>
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div className="w-full h-c-52 pb-1 bg-primary-l1/30 pb-1 rounded-lg">
                      <button
                        onClick={onJoinWaitList}
                        className="w-full h-12 px-2 py-3 text-sm font-primary font-bold font-wght-740 leading-[1.05rem] gap-2 rounded-lg text-black bg-primary-l1 border-1 border-black">
                        Join waitlist
                      </button>
                    </div>
                  )}
                </>
              )
            ) : (
              <div className="w-full mt-1">
                {!onboardingFeatureFlag ? (
                  <div className={clsx([campaign?.callToActionUrl?.length ? "grid grid-cols-2 gap-2" : "grid grid-cols-1"])}>
                    {campaign?.callToActionUrl?.length ? (
                      <button
                        onClick={() => {
                          mixpanel.track("take-action-clicked", {
                            location: "campaign-profile",
                            campaignName: campaign?.title,
                            charityName: campaign?.charity?.name
                          });
                          window.open(formatUrlWithProtocol(campaign?.callToActionUrl), "_blank");
                        }}
                        className="flex h-12 gap-2 w-full px-2 items-center text-base font-primary tracking-wider font-bold rounded-lg text-base-white text-sm bg-grey/20 border border-grey/70 align-center justify-center whitespace-nowrap">
                        <img
                          src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "68", "68")}
                          alt="Logo"
                          className="w-[34px] h-[34px] rounded-[999px] pure-black border-[1px] border-solid border-[#90A8C1]/15"
                        />
                        Take action
                      </button>
                    ) : null}
                    <>
                      {isUserJoinedWaitlist ? (
                        <div className="w-full">
                          <button className="w-full h-12 px-4 py-3 items-center text-base font-primary tracking-wider leading-tight-19.2 font-bold rounded-lg text-sm text-grey bg-grey/20 border border-grey/70 flex align-center justify-center">
                            On waitlist
                            <span className="ml-1.5 w-6 h-6 relative -top-0.5">
                              <img src={FollowingCheck} alt="On waitlist Check"></img>
                            </span>
                          </button>
                        </div>
                      ) : (
                        <div className="w-full h-12 pb-1 bg-primary-l1/30 pb-1 rounded-lg">
                          <button
                            onClick={onJoinWaitList}
                            className="w-full px-2 py-3.5 text-sm font-primary font-bold font-wght-740 leading-[1.05rem] gap-2 rounded-lg text-black bg-primary-l1 border-1 border-black">
                            Join waitlist
                          </button>
                        </div>
                      )}
                    </>
                  </div>
                ) : (
                  //   <Button
                  //   onClick={() => {
                  //     mixpanel.track("follow-button-clicked");
                  //     navigation.push({
                  //       pathname: routes.onboarding,
                  //       state: {
                  //         userName: celebrityName,
                  //         followerProfileImage: userProfile,
                  //         param: { isFollow: true, stashLinkId: stashlink.id }
                  //       }
                  //     });
                  //   }}
                  //   isDisabled={false}
                  //   title={`Follow ${celebrityName?.split(" ")[0]}`}
                  // />
                  <>
                    {isUserJoinedWaitlist ? (
                      <div className="w-full">
                        <button className="w-full h-12 px-4 py-3 items-center text-base font-primary tracking-wider leading-tight-19.2 font-bold rounded-lg text-sm text-grey bg-grey/20 border border-grey/70 flex align-center justify-center">
                          On waitlist
                          <span className="ml-1.5 w-6 h-6 relative -top-0.5">
                            <img src={FollowingCheck} alt="On waitlist Check"></img>
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div className="w-full h-c-52 pb-1 bg-primary-l1/30 pb-1 rounded-lg">
                        <button
                          onClick={onJoinWaitList}
                          className="w-full h-12 px-2 py-3 text-base font-primary font-wght-750 leading-[1.05rem] gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black">
                          Join waitlist
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            {isCurrentUserStashLink ? (
              <div className="text-center mt-3">
                <span className="text-base-white font-semibold font-primary text-xl">{formatCurrencyToUsd(campaign?.currentAmount)}</span>
                <span className="text-primary-l1 font-bold font-secondary mx-3">raised of {formatCurrencyToUsd(campaign?.goalAmount)}</span>
                <div className="w-full gap-8.01 bg-primary-l1/30 pb-1.5 rounded-lg mt-3">
                  <button
                    className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-740 gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black"
                    onClick={() => {
                      mixpanel.track("bottom-bar-share-button-clicked");
                      openShareModal();
                    }}>
                    {buttonTitle.share}
                  </button>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-12 gap-1 flex content-center justify-between items-center mt-1.5">
                <div className="flex items-center justify-center col-span-12 content-center">
                  <p className="text-22px font-primary leading-normal font-bold text-neutral-50 tracking-tight">
                    {formatCurrencyToUsd(campaign?.currentAmount)}
                  </p>
                  <p className="text-sm leading-5 font-secondary font-normal text-neutral-50 ml-1.5">
                    raised of {formatCurrencyToUsd(campaign?.goalAmount)}
                  </p>
                </div>
                {gamePlayCount > 1 ? (
                  <div className="w-full col-span-12 pb-1.5 rounded-lg">
                    {!onboardingFeatureFlag ? (
                      <>
                        {isUserJoinedWaitlist ? (
                          <div className="w-full gap-2.5 pb-1.5 rounded-lg">
                            <button className="w-full px-2 py-2 min-h-c-48 text-base font-primary tracking-wider font-bold rounded-lg text-grey bg-grey/20 border border-grey/70 flex align-center justify-center flex items-center">
                              On waitlist
                              <span className="ml-1 w-6 h-6">
                                <img src={FollowingCheck} alt="On waitlist Check"></img>
                              </span>
                            </button>
                          </div>
                        ) : (
                          <div className="w-full h-12 pb-1 bg-primary-l1/30 pb-1 rounded-lg">
                            <button
                              onClick={onJoinWaitList}
                              className="w-full px-2 py-3 text-base font-primary font-wght-740 shadow-[0px_4px_0px_#6C801F] leading-tight gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black">
                              Join waitlist
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {colinkExistData ? (
                          <button className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-740  rounded-lg text-grey bg-grey/30 border border-grey flex align-center justify-center">
                            Teamed Up{" "}
                            <span className="ml-0">
                              <img src={FollowingCheck} alt="Following Check"></img>
                            </span>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              if (isUserLoggedIn) {
                                mixpanel.track("team-up-button-clicked", {
                                  campaignName: campaign?.title,
                                  charityName: campaign?.charity?.name
                                });
                                navigation.push({
                                  pathname: routes.callback,
                                  state: { currentPath: location.pathname, from: routes.colink, fromValidFlow: true }
                                });
                              } else {
                                mixpanel.track("sign-up-button-clicked");
                                navigation.push({
                                  pathname: routes.onboarding,
                                  state: { from: location.pathname, userName: celebrityName, followerProfileImage: userProfile }
                                });
                              }
                            }}
                            className={`w-full h-12 px-4 py-3 font-primary text-base leading-tight  tracking-wider font-wght-740 gap-2 rounded-lg text-black border-2 border-black ${
                              isUserLoggedIn
                                ? "bg-primary-l1 shadow-[0px_4px_0px_#6C801F]"
                                : "bg-primary-l1 shadow-[0px_4px_0px_#6C801F] text-xl !py-2"
                            }`}>
                            {isUserLoggedIn ? buttonTitle.teamUp : buttonTitle.signUp}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="w-full col-span-12 bg-primary-l1/30 pb-1 mb-3 rounded-lg h-c-52">
                    <button
                      onClick={onToggle}
                      className="w-full h-12 px-2 py-2 text-xl font-primary font-wght-750 leading-tight gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black">
                      {gamePlayCount === 0 ? buttonTitle.playToFund : buttonTitle.playAgain}
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PlayToFund;
