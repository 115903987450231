import { useState, useEffect } from "react";
import { getImpactLevel, getLocalStorage, setLocalStorage } from "../../utils/common-function";
import { User } from "../../types";

export const useImpactScoreModal = (currentUser?: User) => {
  const [isImpactScoreModalOpen, setIsImpactScoreModalOpen] = useState<boolean>(false);
  const [impactScoreLevel, setImpactScoreLevel] = useState<string | null>(null);

  useEffect(() => {
    if (currentUser) {
      const currentImpactScoreLevel = getLocalStorage("impactScoreLevel") as string;
      const newImpactScoreLevel = currentUser.impactScore && getImpactLevel(currentUser.impactScore);

      newImpactScoreLevel && setImpactScoreLevel(newImpactScoreLevel);

      if (!currentImpactScoreLevel && newImpactScoreLevel) {
        setLocalStorage("impactScoreLevel", newImpactScoreLevel);
        setIsImpactScoreModalOpen(true);
      } else if (newImpactScoreLevel) {
        if (newImpactScoreLevel !== currentImpactScoreLevel) {
          setLocalStorage("impactScoreLevel", newImpactScoreLevel);
          setIsImpactScoreModalOpen(true);
        } else {
          setIsImpactScoreModalOpen(false);
        }
      }
    }
  }, [currentUser]);

  return { isImpactScoreModalOpen, setIsImpactScoreModalOpen, impactScoreLevel };
};
