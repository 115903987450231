import Loader from "../../../components/common/loader/loader";
import useRedirectCallback from "./hooks/useRedirectCallback";

const RedirectCallback = () => {
  useRedirectCallback();
  return (
    <div className="h-100-57 w-screen flex justify-center items-center bg-soft-black">
      <Loader />
    </div>
  );
};

export default RedirectCallback;
