import { useCallback, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { filterNameInput, getLocalStorage } from "../../../../utils/common-function";
import useUser from "../../../../services/hooks/useUser";
import { ReactCropperElement } from "react-cropper";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { uploadPhoto } from "../../../../common/constants";

const useEditProfile = () => {
  const history = useHistory();
  const location = useLocation();
  const userId = getLocalStorage("userId");
  const [isProfilePictureChange, setProfilePictureChange] = useState(false);
  const { userData, updateUserProfile } = useUser(userId);
  const [profileImageUri, setProfileImageUri] = useState<string>(userData?.profilePhoto ?? "");
  const [croppedProfileImage, setCroppedProfileImage] = useState<File>();
  const [firstName, setFirstName] = useState<string>(userData?.fullName.split(" ")[0] ?? "");
  const [lastName, setLastName] = useState<string>(userData?.fullName.split(" ")[1] ?? "");
  const [userBio, setUserBio] = useState<string>(userData?.description ?? "");
  const [birthday, setBirthday] = useState(userData?.birthdate ?? "");
  const [instagramId, setInstagramId] = useState<string>(userData?.instagramUsername ?? "");
  const [tiktokId, setTiktokId] = useState<string>(userData?.tiktokUsername ?? "");
  const [youtube, setYoutube] = useState<string>(userData?.youtubeUsername ?? "");
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setProfilePicture } = useAuth();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(birthday));

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      setBirthday(`${year}-${month}-${day}`);
      setSelectedDate(date);
    }
  }, []);

  const onChangeSetInitialCrop = () => {
    if (cropperRef.current && cropperRef.current.cropper && profileImageUri) {
      const cropper = cropperRef.current.cropper;
      cropper.setCropBoxData({
        width: 150,
        height: 150,
        left: (cropper.getContainerData().width - 150) / 2,
        top: (cropper.getContainerData().height - 150) / 2
      });
    }
  };

  const handlePhotoChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const validImageTypes = uploadPhoto.validFileExtensions;

    if (file) {
      if (!validImageTypes.includes(file.type)) {
        setError(uploadPhoto.imageTypeMessage);
      } else {
        setError("");
        setImage(file);
        setProfileImageUri(URL.createObjectURL(file));
        setProfilePictureChange(true);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, []);

  const handleCrop = useCallback(async () => {
    const cropperInstance = cropperRef.current?.cropper;
    if (cropperInstance) {
      const croppedCanvas = cropperInstance.getCroppedCanvas(uploadPhoto.dimensions);
      croppedCanvas.toBlob(
        async (blob: Blob | null) => {
          if (blob) {
            const croppedImageUrl = URL.createObjectURL(blob);
            setProfileImageUri(croppedImageUrl);
            const editedFile = new File([blob], image?.name || uploadPhoto.defaultImageName, {
              type: image?.type || uploadPhoto.validFileExtensions[0]
            });
            setProfilePicture(editedFile);
            setProfilePictureChange(false);
            setCroppedProfileImage(editedFile);
          }
        },
        image?.type || uploadPhoto.validFileExtensions[0],
        1
      );
    }
  }, [image, history, setProfilePicture]);

  const previousPagePath = useMemo((): string => {
    const location = useLocation<any>();
    return location.state?.from ?? "/";
  }, [location]);

  const onChangeFirstName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredValue = filterNameInput(value);
    setFirstName(filteredValue.trim());
  }, []);

  const onChangeLastName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredValue = filterNameInput(value);
    setLastName(filteredValue.trim());
  }, []);

  const onChangeBio = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserBio(event.target.value);
  }, []);

  const isSaveButtonDisable = useMemo(() => firstName.length === 0 || lastName.length === 0, [firstName, lastName]);

  const onBirthdayChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setBirthday(event.target.value);
  }, []);

  const onChangeInstagramId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInstagramId(event.target.value);
  }, []);

  const onChangeTiktokId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTiktokId(event.target.value);
  }, []);

  const onChangeYoutube = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setYoutube(event.target.value);
  }, []);

  const onEditProfileSuccess = useCallback(() => {
    const newState = location.state as Record<string, any>;
    history.push({ pathname: previousPagePath, state: { ...newState, fromValidFlow: true, from: newState.lastPath } });
  }, [history, location, previousPagePath]);

  const onEditProfileError = useCallback(() => {
    setLoading(false);
    setError(uploadPhoto.updateAPIErrorMessage);
  }, []);

  const onClickSaveHandler = useCallback(async () => {
    setLoading(true);
    const dob = birthday.split("-");
    const updatedUserProfile = {
      fullName: `${firstName} ${lastName}`,
      birthdate: `${dob[2]}-${dob[1]}-${dob[0]}`,
      description: userBio,
      profileImageUrl: croppedProfileImage,
      instagramUsername: instagramId,
      tiktokUsername: tiktokId,
      youtubeUsername: youtube
    };
    try {
      await updateUserProfile(updatedUserProfile, onEditProfileSuccess, onEditProfileError);
    } catch {
      setError(uploadPhoto.updateAPIErrorMessage);
    } finally {
      setLoading(false);
    }
  }, [firstName, history, lastName, location, youtube, instagramId, tiktokId, userBio, birthday, croppedProfileImage]);

  const onAddPhotoButtonHandler = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const cancelCrop = useCallback(() => {
    setProfileImageUri(userData?.profilePhoto ?? "");
    setProfilePictureChange(false);
  }, [userData?.profilePhoto]);

  return {
    history,
    cropperRef,
    previousPagePath,
    isProfilePictureChange,
    error,
    loading,
    profileImageUri,
    firstName,
    lastName,
    userBio,
    birthday,
    instagramId,
    tiktokId,
    youtube,
    onChangeSetInitialCrop,
    onAddPhotoButtonHandler,
    handlePhotoChange,
    handleCrop,
    setProfileImageUri,
    onChangeFirstName,
    onChangeLastName,
    onChangeBio,
    onBirthdayChange,
    onChangeInstagramId,
    onChangeTiktokId,
    onChangeYoutube,
    onClickSaveHandler,
    isSaveButtonDisable,
    cancelCrop,
    fileInputRef,
    selectedDate,
    handleDateChange
  };
};

export default useEditProfile;
