import React from "react";
import { convertToPercentage, generateProfileImageUrl, getStyles } from "../../utils/common-function";
import { CampaignCardProps } from "./campaignCard.types";
import { useHistory } from "react-router-dom";
import { formatCurrencyToUsd } from "../../utils/format";
import ProgressBar from "../common/progressBar";
import ShareIcon from "./../../assets/images/shareIconArrow.svg";
import verifiedWhite from "./../../assets/images/grey-transparent-verified-badge.png";

const CampaignCard: React.FC<CampaignCardProps> = (props: CampaignCardProps) => {
  const history = useHistory();
  const campaign = props.stashlink.campaign;
  const parentStashlink = props.stashlink.parentStashlink;
  const parentUser = parentStashlink?.user;
  const percentageCompleted = convertToPercentage(campaign.currentAmount, campaign.goalAmount);
  // This is temporary function will replace by hook in future
  const navigateFunction = () => {
    history.push(`/stashlink/${props.stashlink.id}`);
  };
  const styles = getStyles(props.impactScoreLevel);

  const openShareModal = () => {
    props.setShareModalStashlink && props.setShareModalStashlink(props.stashlink);
    props.setIsShareModalOpen && props.setIsShareModalOpen(true);
  };

  return (
    <div
      className="relative mb-8 rounded-xl rounded-tl-3xl shadow-custom shadow-black min-w-[300px]"
      style={{ background: styles.levelColor }}
      onClick={() => navigateFunction()}>
      <div className="bg-black/75 rounded-xl rounded-tl-3xl">
        <img src={campaign.imageUrl} alt={campaign.title} className="w-full h-38 object-cover rounded-tl-3xl rounded-tr-xl" />
        <img
          src={campaign.charity?.imageUrl}
          alt="Logo"
          className="absolute -top-2 -left-2 w-20 h-20 rounded-full border-8 border-soft-black object-cover"
        />
        <div className="p-4">
          <h3 className="text-neutral-50 font-primary text-base font-bold leading-[19.2px] tracking-[0.004em] text-left">
            {campaign.title}
          </h3>
          <p className="font-secondary font-normal text-sm text-base-white/80 mt-1 leading-[18.2px]">{campaign.description}</p>
          {parentUser && (
            <p className="flex items-center  my-4">
              <img
                src={generateProfileImageUrl(parentUser?.profilePhoto || "", "65", "65")}
                className="w-[28px] h-[28px] rounded-full object-cover border-[1.4px] border-[#00000040] user-icon mr-2"
                alt="Profile"
              />
              <span className="font-primary font-medium text-[14px] leading-[16.8px] tracking-[0.015em] mr-1">with</span>
              <span className="font-primary font-bold text-[14px] leading-[16.8px] tracking-[0.04em]">
                {parentUser?.fullName}
                {parentUser?.isPublicProfile && (
                  // <VerifiedIcon color={styles.levelColor} className="w-3 h-3 inline-block -ml-px align-middle relative -top-1.5" />
                  <img
                    src={verifiedWhite}
                    alt={verifiedWhite}
                    className="w-3 h-3 inline-block -ml-px align-middle relative -top-1.5 ml-1"
                  />
                )}
              </span>
            </p>
          )}
          <div className="mt-2">
          <ProgressBar percentageCompleted={percentageCompleted} />
          <p className="text-neutral-50 font-primary text-base font-bold leading-[19.2px] tracking-[0.004em] text-left mt-2">
              {formatCurrencyToUsd(campaign?.currentAmount)}{" "}
              <span className="font-secondary text-xs font-normal leading-[16.8px]">
                raised of {formatCurrencyToUsd(campaign.goalAmount)}
              </span>
            </p>
          </div>
        </div>
        <div className="p-0.5 bg-black/40 rounded-full flex justify-center items-center absolute top-3 right-3 backdrop-filter-blur w-29 h-8">
          <img
            src={ShareIcon}
            alt={ShareIcon}
            onClick={(e) => {
              e.stopPropagation(); // Prevents triggering the main div's onClick
              openShareModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
