import Button from "../common/button";
import InputField from "../common/input/input";
import ErrorText from "../errorText";
import { buttonTitle, enterYourNameAttributes, labels, names } from "../../modules/Accounts/Onboarding/constant";
import TextArea from "../common/text-area/text-area";
import { ProfileInputsProps } from "./types";
import ProfileInput from "./profileInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ProfileInputs: React.FC<ProfileInputsProps> = ({
  firstName,
  lastName,
  onChangeFirstName,
  onChangeLastName,
  userBio,
  onChangeBio,
  instagramId,
  onChangeInstagramId,
  tiktokId,
  onChangeTiktokId,
  youtube,
  onChangeYoutube,
  onAddPhotoButtonHandler,
  fileInputRef,
  handlePhotoChange,
  profileImageUri,
  error,
  buttonDisabled,
  onClickSaveHandler,
  selectedDate,
  handleDateChange
}) => {
  const minDate = new Date("1900-01-01");
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 16);

  return (
    <div className="w-full pt-3 pb-20">
      <div className="bg-primary-l1 rounded-t-2xl mt-12">
        <div className="bg-black/70 rounded-t-2xl">
          <div className="bg-gradient-to-t from-black/30 to-black/0 rounded-t-2xl p-4 edit-profile">
            <div className="flex flex-col items-center mb-8">
              <div className="w-c-170 h-182 bg-primary-l1 rounded-full flex items-center justify-center -mt-16">
                <img src={profileImageUri} alt="Profile" className="rounded-full w-c-156 h-c-156 object-cover relative top-2" />
              </div>

              <button className="text-grey text-sm font-normal font-secondary mt-2" onClick={onAddPhotoButtonHandler}>
                Edit Photo
                <input ref={fileInputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handlePhotoChange} />
              </button>
            </div>

            <ProfileInput
              value={firstName}
              onChange={onChangeFirstName}
              label={enterYourNameAttributes.label.firstName}
              name={enterYourNameAttributes.name.firstName}
              placeholder={enterYourNameAttributes.placeHolder.firstName}
              errorMessage="First name should be there."
            />

            <ProfileInput
              value={lastName}
              onChange={onChangeLastName}
              label={enterYourNameAttributes.label.lastName}
              name={enterYourNameAttributes.name.lastName}
              placeholder={enterYourNameAttributes.placeHolder.lastName}
              errorMessage="Last name should be there."
            />

            <TextArea
              label={labels.bio}
              name={names.bio}
              value={userBio}
              onChange={onChangeBio}
              placeholder="You can add a short bio to tell people more about yourself"
              rows={2}
              inputClassName="!bg-grey/5 !text-neutral-50 !placeholder:grey/50"
            />

            <div className="date-picker-input input mt-2 w-full mb-4">
              <label className="block text-placeholder-grey text-sm font-normal mb-2">
                Birthday (Your birthday won&apos;t be shown publicly)
              </label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                className="!bg-grey/5 !text-neutral-50 !placeholder:grey/50 !w-full p-2 !border !border-gray-300 rounded text-secondary text-sm" // Add your custom styles
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                minDate={minDate}
                maxDate={maxDate}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                onFocus={(e) => e.target.blur()}
              />
            </div>

            {["Instagram", "Tiktok", "Youtube"].map((platform, index) => (
              <InputField
                key={index}
                value={{ Instagram: instagramId, Tiktok: tiktokId, Youtube: youtube }[platform] as string}
                onChange={
                  {
                    Instagram: onChangeInstagramId,
                    Tiktok: onChangeTiktokId,
                    Youtube: onChangeYoutube
                  }[platform] as (e: React.ChangeEvent<HTMLInputElement>) => void
                }
                label={`${platform} (optional)`}
                name={`${platform.toLowerCase()}Id`}
                placeholder="user name"
                inputClassName="!bg-grey/5 !text-neutral-50 !placeholder:grey/50"
              />
            ))}

            {error && <ErrorText text={error} />}

            <div className="fixed bottom-0 left-0 w-full p-4 bg-black z-10">
              <Button title={buttonTitle.save} isDisabled={buttonDisabled} onClick={onClickSaveHandler} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInputs;
