import { useEffect, useState } from "react";
import { Switch, Route } from "react-router";
import AppWrapper from "../containers/appWrapper";
import DesktopView from "../components/desktopView/desktopView";
import { GrowthBookProvider, useFeatureIsOn } from "@growthbook/growthbook-react";
import { growthbook } from "../common/constants";
import ErrorScreen from "../components/common/errorScreen";

// Render route with sub routes
export const RootRouteComponentWithSubRoutes = ({ routes }: { routes: any }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  const initGrowthbook = async () => {
    await growthbook.init(); // Load features asynchronously
  };

  useEffect(() => {
    initGrowthbook();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isDesktop ? (
    <DesktopView />
  ) : (
    <Switch>
      {routes.map((route: any) => {
        const { key, ...rest } = route;
        return <RouteWithSubRoutes key={key} {...rest} />;
      })}
      <Route component={ErrorScreen} />
    </Switch>
  );
};

// Wrap Routes in AppWrapper (Can create any wrapper based on different requirement/layout like login, faq, etc)
export const RootAppComponentWithRoutes = ({ routes }: { routes: any }) => (
  <GrowthBookProvider growthbook={growthbook}>
    <AppWrapper>
      <RootRouteComponentWithSubRoutes routes={routes} />
    </AppWrapper>
  </GrowthBookProvider>
);

// Allows for rendering nested Route components
export const RouteWithSubRoutes = (route: any) => {
  // Use GrowthBook feature flag check
  const isFeatureEnabled = route.featureFlag ? useFeatureIsOn(route.featureFlag) : true;

  const ComponentToRender = isFeatureEnabled ? route.component : route.redirectComponent;

  return <Route path={route.path} exact={route.exact} render={(props) => <ComponentToRender {...props} routes={route.routes} />} />;
};
